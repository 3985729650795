import { _ as _applyDecoratedDescriptor } from '../_rollupPluginBabelHelpers-15477962.js';
import { setComponentTemplate } from '@ember/component';
import { hbs } from 'ember-cli-htmlbars';
import { action } from '@ember/object';
import Component from '@glimmer/component';

var TEMPLATE = hbs("<input\n  type=\'text\'\n  class=\'input-mask\'\n  value={{@value}}\n  ...attributes\n  {{did-insert this._initialize}}\n  {{on \'input\' this._onInput}}\n  {{inputmask this.args}}\n/>");

var _class;
let InputmaskComponent = (_class = class InputmaskComponent extends Component {
  _initialize(element) {
    this.element = element;
  }

  _onInput() {
    if (this.args.update && typeof this.args.update === 'function') {
      this.args.update(this.element.inputmask.unmaskedvalue());
    }
  }

}, (_applyDecoratedDescriptor(_class.prototype, "_initialize", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_initialize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInput"), _class.prototype)), _class);
setComponentTemplate(TEMPLATE, InputmaskComponent);

export { InputmaskComponent as default };
