import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
export default template(`
  <footer>
    <span><FaIcon @icon='user-circle' />
      {{@currentUserEmail}}
    </span>
    <LinkTo class='logout' @route='logout'>
      {{t 'pages.session-supervising.login.form.actions.switch-account'}}
    </LinkTo>
  </footer>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
