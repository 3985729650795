import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import get from 'lodash/get';
import LoginSessionSupervisorFooter from './footer';
import LoginSessionSupervisorForm from './form';
import LoginSessionSupervisorHeader from './header';
export default class LoginSessionSupervisor extends Component {
    @service
    intl;
    @tracked
    errorMessage = null;
    sessionId;
    supervisorPassword;
    @action
    setSupervisorPassword(event1) {
        this.supervisorPassword = event1.target.value;
    }
    @action
    setSessionId(event1) {
        this.sessionId = event1.target.value;
    }
    @action
    async superviseSession(event1) {
        event1.preventDefault();
        if (!this.sessionId || !this.supervisorPassword) {
            this._displayError(this.intl.t('pages.session-supervising.login.form.errors.mandatory-fields'));
            return;
        }
        try {
            await this.args.authenticateSupervisor({
                sessionId: this.sessionId,
                supervisorPassword: this.supervisorPassword
            });
        } catch (error1) {
            let errorMessage1 = get(error1, 'errors[0].detail');
            const errorCode1 = get(error1, 'errors[0].code');
            if (errorCode1 === 'INCORRECT_DATA') {
                errorMessage1 = this.intl.t('pages.session-supervising.login.form.errors.incorrect-data');
            }
            return this._displayError(errorMessage1);
        }
    }
    _displayError(message1) {
        this.errorMessage = message1;
    }
    static{
        template(`
    <div id='login-session-supervisor-page'>
      <main>
        <section>
          <LoginSessionSupervisorHeader @errorMessage={{this.errorMessage}} />

          <LoginSessionSupervisorForm
            @superviseSession={{this.superviseSession}}
            @setSessionId={{this.setSessionId}}
            @setSupervisorPassword={{this.setSupervisorPassword}}
          />

          <p class='description'>
            {{t 'pages.session-supervising.login.form.description'}}
          </p>
        </section>

        <LoginSessionSupervisorFooter @currentUserEmail={{@currentUserEmail}} />
      </main>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
