/* import __COLOCATED_TEMPLATE__ from './enrolled-candidates.hbs'; */
import EmberObject, { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import { SUBSCRIPTION_TYPES } from 'pix-certif/models/subscription';

const TRANSLATE_PREFIX = 'pages.sessions.detail.candidates';

export default class EnrolledCandidates extends Component {
  @service store;
  @service intl;
  @service currentUser;
  @service notifications;
  @service featureToggles;
  @tracked candidatesInStaging = [];
  @tracked newCandidate = {};
  @tracked shouldDisplayCertificationCandidateModal = false;
  @tracked shouldDisplayEditCertificationCandidateModal = false;
  @tracked certificationCandidateInDetailsModal = null;
  @tracked certificationCandidateInEditModal = null;
  @tracked showNewCandidateModal = false;

  get shouldDisplayAccessibilityAdjustmentNeededFeature() {
    return (
      this.currentUser.currentAllowedCertificationCenterAccess.isV3Pilot &&
      this.featureToggles.featureToggles?.isNeedToAdjustCertificationAccessibilityEnabled
    );
  }

  @action
  async deleteCertificationCandidate(certificationCandidate) {
    this.notifications.clearAll();
    const sessionId = this.args.sessionId;

    try {
      await certificationCandidate.destroyRecord({ adapterOptions: { sessionId } });
      this.notifications.success(this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.success-remove`));
    } catch (error) {
      let errorText = this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.error-remove-unknown`);
      if (get(error, 'errors[0].code') === 403) {
        errorText = this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.error-remove-already-in`);
      }
      this.notifications.error(errorText);
    }
  }

  @action
  addCertificationCandidateInStaging() {
    let addedAttributes = {};
    if (this.args.shouldDisplayPaymentOptions) {
      addedAttributes = {
        billingMode: '',
        prepaymentCode: '',
      };
    }
    this.newCandidate = EmberObject.create({
      firstName: '',
      lastName: '',
      birthdate: '',
      birthCity: '',
      birthCountry: 'FRANCE',
      email: '',
      externalId: '',
      resultRecipientEmail: '',
      birthPostalCode: '',
      birthInseeCode: '',
      sex: '',
      extraTimePercentage: '',
      subscriptions: [],
      ...addedAttributes,
    });
  }

  @action
  async addCertificationCandidate(candidate) {
    const certificationCandidate = { ...candidate };
    certificationCandidate.extraTimePercentage = this._fromPercentageStringToDecimal(candidate.extraTimePercentage);
    const success = await this.saveCertificationCandidate(certificationCandidate);
    if (success) {
      this.candidatesInStaging.removeObject(candidate);
      this.closeNewCandidateModal();
    }
    return success;
  }

  @action
  removeCertificationCandidateFromStaging(candidate) {
    this.candidatesInStaging.removeObject(candidate);
  }

  @action
  updateCertificationCandidateInStagingFieldFromEvent(candidateInStaging, field, ev) {
    const { value } = ev.target;

    candidateInStaging.set(field, value);
  }

  @action
  updateCertificationCandidateInStagingFieldFromValue(candidateInStaging, field, value) {
    candidateInStaging.set(field, value);
  }

  @action
  updateEditCandidateInStagingFieldFromValue(candidateInStaging, field, event) {
    candidateInStaging.set(field, event.target.checked);
  }

  @action
  async updateCandidate(event) {
    event.preventDefault();
    try {
      const adapter = this.store.adapterFor('certification-candidate');
      await adapter.updateRecord({ candidate: this.certificationCandidateInEditModal, sessionId: this.args.sessionId });
      this.notifications.success(this.intl.t('pages.sessions.detail.candidates.edit-modal.notifications.success'));
      this.closeEditCandidateModal();
    } catch (e) {
      this.notifications.error(this.intl.t('pages.sessions.detail.candidates.edit-modal.notifications.error'));
    } finally {
      this.args.reloadCertificationCandidate();
    }
  }

  @action
  updateCertificationCandidateInStagingBirthdate(candidateInStaging, value) {
    candidateInStaging.set('birthdate', value);
  }

  @action
  async saveCertificationCandidate(certificationCandidateData) {
    this.notifications.clearAll();
    const { certificationCandidate, subscriptions } =
      this._createCertificationCandidateRecord(certificationCandidateData);

    if (this._hasDuplicate(certificationCandidate)) {
      this._handleDuplicateError(certificationCandidate);
      return;
    }

    try {
      await certificationCandidate.save({
        adapterOptions: { registerToSession: true, sessionId: this.args.sessionId, subscriptions },
      });
      this.args.reloadCertificationCandidate();
      this.notifications.success(this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.success-add`));
      return true;
    } catch (errorResponse) {
      const status = get(errorResponse, 'errors[0].status');

      const errorText = this._getErrorText({ status, errorResponse });
      this._handleSavingError({ errorText, certificationCandidate });
      return false;
    }
  }

  @action
  openCertificationCandidateDetailsModal(candidate) {
    this.shouldDisplayCertificationCandidateModal = true;
    this.certificationCandidateInDetailsModal = candidate;
  }

  @action
  openEditCertificationCandidateDetailsModal(candidate) {
    this.shouldDisplayEditCertificationCandidateModal = true;
    this.certificationCandidateInEditModal = candidate;
  }

  @action
  closeCertificationCandidateDetailsModal() {
    this.shouldDisplayCertificationCandidateModal = false;
    this.certificationCandidateInDetailsModal = null;
  }

  @action
  openNewCandidateModal() {
    this.addCertificationCandidateInStaging();
    this.showNewCandidateModal = true;
  }

  @action
  closeNewCandidateModal() {
    this.showNewCandidateModal = false;
  }

  @action
  closeEditCandidateModal() {
    this.shouldDisplayEditCertificationCandidateModal = false;
  }

  _createCertificationCandidateRecord(certificationCandidateData) {
    const subscriptions = certificationCandidateData.subscriptions;
    delete certificationCandidateData.subscriptions;
    if (
      !this.currentUser.currentAllowedCertificationCenterAccess.isCoreComplementaryCompatibilityEnabled ||
      subscriptions.length === 0
    ) {
      subscriptions.push({
        type: SUBSCRIPTION_TYPES.CORE,
        complementaryCertificationId: null,
      });
    }
    return {
      subscriptions,
      certificationCandidate: this.store.createRecord('certification-candidate', certificationCandidateData),
    };
  }

  _getErrorText({ status, errorResponse }) {
    switch (status) {
      case '409':
        return this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.error-add-duplicate`);
      case '422':
        return this._handleEntityValidationError(errorResponse);
      case '400':
        return this._handleMissingQueryParamError(errorResponse);
      default:
        return this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.error-add-unknown`);
    }
  }

  _handleEntityValidationError(errorResponse) {
    const error = errorResponse?.errors?.[0];
    if (error?.code) {
      return this.intl.t(`common.api-error-messages.certification-candidate.${error.code}`, {
        ...error?.meta,
      });
    }
  }

  _handleMissingQueryParamError(errorResponse) {
    const error = errorResponse?.errors?.[0];
    if (error?.detail === 'CANDIDATE_BIRTHDATE_FORMAT_NOT_VALID') {
      return this.intl.t(`common.api-error-messages.certification-candidate.${error.detail}`);
    }
  }

  _handleSavingError({ errorText, certificationCandidate }) {
    const error = errorText ?? this.intl.t(`common.api-error-messages.internal-server-error`);
    this.notifications.error(error);
    certificationCandidate.deleteRecord();
  }

  _handleDuplicateError(certificationCandidate) {
    const errorText = this.intl.t(`${TRANSLATE_PREFIX}.add-modal.notifications.error-add-duplicate`);
    this._handleSavingError({ errorText, certificationCandidate });
  }

  _fromPercentageStringToDecimal(value) {
    return value ? toNumber(value) / 100 : value;
  }

  _hasDuplicate(certificationCandidate) {
    const currentFirstName = certificationCandidate.firstName;
    const currentLastName = certificationCandidate.lastName;
    const currentBirthdate = certificationCandidate.birthdate;

    return (
      this.args.certificationCandidates.find(
        ({ lastName, firstName, birthdate }) =>
          lastName.toLowerCase() === currentLastName.toLowerCase() &&
          firstName.toLowerCase() === currentFirstName.toLowerCase() &&
          birthdate === currentBirthdate,
      ) !== undefined
    );
  }

  get showCompatibilityTooltip() {
    return this.currentUser.currentAllowedCertificationCenterAccess.isCoreComplementaryCompatibilityEnabled;
  }

  computeSubscriptionsText = (candidate) => {
    const complementaryCertificationList = this.args.complementaryCertifications ?? [];
    const subscriptionLabels = [];

    if (candidate.hasDualCertificationSubscriptionCoreClea(complementaryCertificationList)) {
      subscriptionLabels.push(this.intl.t(`${TRANSLATE_PREFIX}.list.subscriptions.dual-core-clea`));
    } else {
      for (const subscription of candidate.subscriptions) {
        if (subscription.isCore) subscriptionLabels.unshift(this.intl.t(`${TRANSLATE_PREFIX}.list.subscriptions.core`));
        else {
          const candidateComplementaryCertification = complementaryCertificationList.find(
            (complementaryCertification) => complementaryCertification.id === subscription.complementaryCertificationId,
          );
          subscriptionLabels.push(candidateComplementaryCertification?.label || '-');
        }
      }
    }

    return subscriptionLabels.join(', ');
  };
}
