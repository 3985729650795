import { template } from "@ember/template-compiler";
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { FRENCH_INTERNATIONAL_LOCALE } from 'pix-certif/services/locale';
export default class LanguageSwitcher extends Component {
    @service
    locale;
    get alphabeticallySortedLanguages() {
        const availableLanguages1 = this.locale.getAvailableLanguages();
        const options1 = Object.entries(availableLanguages1).filter(([_1, config1])=>config1.languageSwitcherDisplayed).map(([key1, config1])=>({
                label: config1.value,
                value: key1
            }));
        const optionsWithoutFrSortedByLabel1 = options1.filter((option1)=>option1.value !== FRENCH_INTERNATIONAL_LOCALE).sort((option1)=>option1.label);
        const frenchLanguageOption1 = options1.find((option1)=>option1.value === FRENCH_INTERNATIONAL_LOCALE);
        return [
            frenchLanguageOption1,
            ...optionsWithoutFrSortedByLabel1
        ];
    }
    static{
        template(`
    <PixSelect
      @id='language-switcher'
      @icon='earth-europe'
      @value={{@selectedLanguage}}
      @options={{this.alphabeticallySortedLanguages}}
      @onChange={{@onLanguageChange}}
      @hideDefaultOption='true'
      @screenReaderOnly='true'
      ...attributes
    >
      <:label>{{t 'common.forms.login.choose-language-aria-label'}}</:label>
    </PixSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
