export default {
  en: {
    value: 'English',
    languageSwitcherDisplayed: true,
  },

  fr: {
    value: 'Français',
    languageSwitcherDisplayed: true,
  },
};
